import classNames from 'classnames';

const ButtonLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <a
      className={classNames(
        'min-h-10 rounded-md leading-4 px-4 py-3 bg-blue-primary text-white text-center font-sans1 font-bold',
        className
      )}
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default ButtonLink;
