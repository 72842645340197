import { ButtonLink } from 'components/ui';
import { ReactComponent as HiFiveLogo } from 'components/assets/logos/hifive.svg';
import { ReactComponent as Arc } from 'components/assets/logos/arc.svg';
import { ReactComponent as Checkmark } from 'components/assets/logos/checkmark.svg';
import { FormattedMessage } from 'react-intl';
import { ButtonClickedDataV1, ScreenViewedDataV3, UserActivatedDataV1 } from '@xero/beanie-schemas';
import { eventCreator, sendEvent, ENVIRONMENT } from '@xero/beanie-js';
import APP_CONSTANTS from 'constants/app';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const userActivatedData: UserActivatedDataV1 = {
  screenName: { value: 'accountActivated' }
};

const userActivatedEvent = eventCreator({
  source: 'urn:kotahi:component:ciXKwLAZR9FtaxeugToUAL',
  category: 'userOutcomeEvent',
  productArea: 'mobileApi',
  sourceDomain: 'xero-applicationplatform-mobile'
});

const userActivated = userActivatedEvent();

const userActivatedBeanieEvent = userActivated({
  type: 'xero.behavioural-event.xero-appplat-usageanalytics.user.activated.v1',
  data: userActivatedData
});

const screnViewedData: ScreenViewedDataV3 = {
  screenName: { value: 'accountActivated' }
};

const screenViewEvent = eventCreator({
  source: 'urn:kotahi:component:ciXKwLAZR9FtaxeugToUAL',
  category: 'screenViewEvent',
  productArea: 'mobileApi',
  sourceDomain: 'xero-applicationplatform-mobile'
});

const unauthenticatedEvent = screenViewEvent();

const screenViewedBeanieEvent = unauthenticatedEvent({
  type: 'xero.behavioural-event.xero-appplat-usageanalytics.screen.viewed.v5',
  data: screnViewedData
});

const data: ButtonClickedDataV1 = {
  buttonName: { value: 'onLoginToXeroPressed' },
  screenName: { value: 'accountActivated' }
};

const buttonClickedEvent = eventCreator({
  source: 'urn:kotahi:component:ciXKwLAZR9FtaxeugToUAL',
  category: 'interactionEvent',
  productArea: 'mobileApi',
  sourceDomain: 'xero-applicationplatform-mobile'
});

const event = buttonClickedEvent();

const buttonClickedBeanieEvent = event({
  type: 'xero.behavioural-event.xero-appplat-usageanalytics.button.clicked.v1',
  data: data
});

const environment =
  process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === 'production'
    ? ENVIRONMENT.PRODUCTION
    : ENVIRONMENT.STAGING;

const oneLinkUrl =
  process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === 'production'
    ? 'https://xeroaccounting.xero.com/KzWl?af_web_dp=https%3A%2F%2Flogin.xero.com%2Fidentity%2Fuser%2Flogin&af_xp=custom&pid=SignUp%20Landing%20Page%20Redirect&deep_link_value=signup_landing_page_redirect&af_dp=xero-touch%3A%2F%2Flogin'
    : 'https://xaa-internal.onelink.me/oGKs?af_web_dp=https%3A%2F%2Flogin.livestage6.test.xero.com%2Fidentity%2Fuser%2Flogin&af_xp=custom&pid=SignUp%20Landing%20Page%20Redirect&af_dp=xero-touch%3A%2F%2Flogin&deep_link_value=signup_landing_page_redirect';

const Activation = () => {
  const flags = useFlags();

  useEffect(() => {
    sendEvent(screenViewedBeanieEvent, environment);
    sendEvent(userActivatedBeanieEvent, environment);
  });

  return (
    <div className="bg-white h-full flex flex-col">
      <div className="relative h-[55%] md:h-[75%] bg-blue-primary flex flex-col justify-end">
        <div className="absolute w-full h-full top-0 left-0">
          <div className="flex flex-col justify-end h-full items-center">
            <div className="w-3/4 sm:w-1/2 md:w-2/5">
              <HiFiveLogo />
            </div>
          </div>
        </div>
        <div className="-mb-0.5">
          <Arc className="h-12" />
        </div>
      </div>

      <div className="flex flex-col justify-around px-8 pb-4 h-full bg-white text-center">
        <div>
          <h2 className="md:text-4xl font-sans font-bold text-3xl">
            <FormattedMessage id={'ACTIVATION_ACTIVATED'} />
          </h2>
          <div className="pt-4 font-sans text-2xl tracking-tight">
            <span className="md:hidden">
              <FormattedMessage id={'ACTIVATION_NEXT_STEP_MOBILE'} />
            </span>
            <span className="hidden md:block">
              <FormattedMessage id={'ACTIVATION_NEXT_STEP_WEB'} />
            </span>
          </div>{' '}
        </div>

        <div className="flex justify-center">
          <div>
            <div className="flex">
              <div className="flex items-center">
                <div className="bg-green-primary p-1 rounded-full">
                  <div className="w-4 h-4">
                    <Checkmark />
                  </div>
                </div>
              </div>
              <div className="line-through text-gray-primary flex-1 text-left pl-2">
                <p className="text-lg md:text-xl">Add account details</p>
              </div>
            </div>
            <div className="p-3">
              <div className="border-l-2 border-gray-300 h-4"></div>
            </div>
            <div className="flex">
              <div className="flex items-center">
                <div className="flex justify-content justify-center bg-[#284867] p-1 rounded-full">
                  <div className="w-4 h-4 leading-none text-white">2</div>
                </div>
              </div>
              <div className="text-[#284867] flex-1 text-left pl-2">
                <p className="text-lg md:text-xl">Tell us about your business</p>
              </div>
            </div>
            <div className="p-3">
              <div className="border-l-2 border-gray-300 h-4"></div>
            </div>
            <div className="flex">
              <div className="flex items-center">
                <div className="flex justify-content justify-center bg-[#284867] p-1 rounded-full">
                  <div className="w-4 h-4 leading-none text-white">3</div>
                </div>
              </div>
              <div className="text-[#284867] flex-1 text-left pl-2">
                <p className="text-lg md:text-xl">Select your plan</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full">
          <ButtonLink
            className="w-full md:w-1/2 block max-w-lg"
            href={flags.useAppsFlyerOneLinkRedirectUrl ? oneLinkUrl : APP_CONSTANTS.DEEPLINK_URL}
            onClick={() => sendEvent(buttonClickedBeanieEvent, environment)}
          >
            <FormattedMessage id={'ACTIVATION_GET_STARTED'} />
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default Activation;
