import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { Activation, NotFound } from 'components/pages';

const Root = () => {
  return (
    <Routes>
      <Route path="/activation" element={<Activation />} />
      <Route path="/" element={<Activation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const router = createBrowserRouter([{ path: '*', Component: Root }]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
