import classNames from 'classnames';

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <button
      className={classNames(
        'min-h-10 rounded-md leading-4 px-4 py-3 bg-blue-primary text-white font-sans font-bold',
        className
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
