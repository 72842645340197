import { Button } from 'components/ui';
import APP_CONSTANTS from 'constants/app';

export type ErrorProps = {
  title: React.ReactNode | string;
  description: React.ReactNode | string;
  button: {
    text: string;
    onClick: () => void;
  };
};

const Error: React.FC<ErrorProps> = ({ title, description, button }) => {
  return (
    <div className="container h-full mx-auto pt-[15%] md:pt-20 px-4">
      <div className="flex items-center justify-center">
        <div className="pb-4">
          <h1 className="my-3.5 font-sans font-extrabold text-blue-900 md:text-3xl md:leading-10">
            {title}
          </h1>
          <div className="text-blue-950">{description}</div>
          <Button onClick={button.onClick}>{button.text}</Button>
        </div>
      </div>
      <div className="sm:h-96 sm:m-14">
        <img className="h-full" src={APP_CONSTANTS.ERROR_LOGO_URL} />
      </div>
    </div>
  );
};

export default Error;
