import AppContextProvider from 'context/app';
import LocalisationWrapper from 'i18n/localisation-wrapper';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import AppRouter from 'router';

const App = () => {
  return (
    <LocalisationWrapper>
      <AppContextProvider>
        <AppRouter />
      </AppContextProvider>
    </LocalisationWrapper>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string
})(App);
